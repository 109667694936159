export default {
    state:{
        isCollapse:false,
        tabsList:[
            {
                path:'/Home/thehome',
                name:'home',
                label:'维修方案决策',
                icon:'s-home',
                url:'Home/Home'
            }
        ]
    },
    mutations:{
        collapseMenu(state){
            state.isCollapse = !state.isCollapse
        },
        selectMenu(state,val){
            console.log(val,'val')
            //判断添加数据是否为首页
            if (val.name !== 'home'){
                const index = state.tabsList.findIndex(item => item.name === val.name)
                if(index === -1){
                    state.tabsList.push(val)
                }
            }
        },
        closeTag(state,item){
            const index = state.tabsList.findIndex(val => val.name === item.name)
            state.tabsList.splice(index,1)

        }
    }
  
  

}
