<template>
  <div style="width:100%;height:100%;overflow: hidden;">
    <div class="routertag">
      <el-tag @click="changeMenu('thehome')">总览</el-tag>
      <el-tag @click="changeMenu('zhendong')">在线监测</el-tag>
      <el-tag @click="changeMenu('guzhang')">故障诊断</el-tag>
      <el-tag @click="changeMenu('shouming')">维修决策</el-tag>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  methods:{
    changeMenu(item) {
      this.$router.push({ name: item });
    },
    
  },
  beforeDestroy() {
    // this.stopPolling();
  },
  mounted() {
  }

};
</script>

<style lang="less" scoped>
.routertag{
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
