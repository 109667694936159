<template>
  <el-row>
    <el-col :span="9">
      <el-card>
        <div class="block" style="width: 100%; height: 150px">
          <span class="demonstration">请输入检索时间</span>
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '00:00:00']"
          >
          </el-date-picker>
        </div>
        <div style="width: 100%; height: 150px">
          <div>选择信号源</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button class="button" type="success">数据查询</el-button>
        </div>
      </el-card>
    </el-col>
    <el-col :span="15">
      <el-card>
        <div style="width: 100%; height: 500px">
          <div>数据展示</div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="1"> </el-table-column>
            <el-table-column prop="2"> </el-table-column>
            <el-table-column prop="3"> </el-table-column>
            <el-table-column prop="4"> </el-table-column>
            <el-table-column prop="5"> </el-table-column>
            <el-table-column prop="6"> </el-table-column>
            <el-table-column prop="7"> </el-table-column>
            <el-table-column prop="8"> </el-table-column>
          </el-table>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      value1: "",
    };
  },
};
</script>

<style lang="less" scoped>
.button {
  float: right;
}
</style>
