<template>
    <div style="width:100%;height:100%;overflow: hidden;">
      <el-row >
        <el-col :span="12">
          <el-card>
            <div
              style="width: 100%; height: 65px; display: flex; font-size: 10px"
              id="input1"
            >
              <div class="sub-title">
                进口压力(kpa)<el-input
                  v-model="freshingData.pressureInlet"
                  :disabled="true"
                  size="mini"
                ></el-input>
              </div>
              <div class="sub-title">
                流量(m3/h)<el-input v-model="freshingData.rateFlow" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                电压0(V)<el-input v-model="freshingData.voltage0" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                电压1(V)<el-input v-model="freshingData.voltage1" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                电压2(V)<el-input v-model="freshingData.voltage2" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                有效功率(Kw)<el-input
                  v-model="freshingData.effectivePowerPump"
                  :disabled="true"
                  size="mini"
                ></el-input>
              </div>
              <div class="sub-title">
                转速(r/min)<el-input
                  v-model="freshingData.speedPump"
                  :disabled="true"
                  size="mini"
                ></el-input>
              </div>
            </div>
            <div
              style="width: 100%; height: 65px; display: flex; font-size: 10px"
              id="input2"
            >
              <div class="sub-title">
                出口压力(kpa)<el-input
                  v-model="freshingData.pressureOutlet"
                  :disabled="true"
                  size="mini"
                ></el-input>
              </div>
              <div class="sub-title">
                扬程(m)<el-input v-model="freshingData.headPump" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                电流0(A)<el-input v-model="freshingData.current0" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                电流1(A)<el-input v-model="freshingData.current1" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                电流2(A)<el-input v-model="freshingData.current2" :disabled="true" size="mini"></el-input>
              </div>
              <div class="sub-title">
                电机输出功率(Kw)<el-input
                  v-model="freshingData.outputPowerMotor"
                  :disabled="true"
                  size="mini"
                ></el-input>
              </div>
              <div class="sub-title">
                装置效率(%)<el-input
                  v-model="freshingData.deviceEfficiency"
                  :disabled="true"
                  size="mini"
                ></el-input>
              </div>
            </div>
            <div style="width: 100%; height: 270px" id="line1"></div>
            <div style="width: 100%; height: 140px" id="table">
              <el-table :data="tableData" height="250" border style="width: 100%">
                <el-table-column prop="date" label="日期" width="100"> </el-table-column>
                <el-table-column
                  prop="gailv"
                  label="状态概率"
                  width="180"
                ></el-table-column>
                <el-table-column prop="xianshi" label="状态显示"> </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <div style="width: 100%; height: 150px" id="line2"></div>
            <div style="width: 100%; height: 150px" id="line3"></div>
            <div style="width: 100%; height: 150px" id="line4"></div>
            <div style="width: 100%; height: 150px" id="line5"></div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card>
            <div style="width: 100%; height: 70px; display: flex; font-size: 10px">
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <span class="demonstration">数据异常检测</span>
                  <el-image
                    style="width: 60px; height: 50px"
                    :src="url1"
                    :fit="fit"
                  ></el-image>
                </div>
              </div>
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <span class="demonstration">早期故障预警</span>
                  <el-image
                    style="width: 60px; height: 50px"
                    :src="url1"
                    :fit="fit"
                  ></el-image>
                </div>
              </div>
            </div>
            <div style="width: 100%; height: 70px; display: flex; font-size: 10px">
              <div class="demo-image1">
                <div class="block" v-for="fit in fits" :key="fit">
                  <span class="demonstration">信号采集</span>
                  <el-image
                    style="width: 60px; height: 50px"
                    :src="url1"
                    :fit="fit"
                  ></el-image>
                </div>
              </div>
              <div class="demo-image1">
                <div class="block" v-for="fit in fits" :key="fit">
                  <span class="demonstration">开始诊断</span>
                  <el-image
                    style="width: 60px; height: 50px"
                    :src="url1"
                    :fit="fit"
                  ></el-image>
                </div>
              </div>
              <div class="demo-image1">
                <div class="block" v-for="fit in fits" :key="fit">
                  <span class="demonstration">故障报警</span>
                  <el-image
                    style="width: 60px; height: 50px"
                    :src="url1"
                    :fit="fit"
                  ></el-image>
                </div>
              </div>
            </div>
  
            <div style="width: 100%; height: 60px; font-size: 15px">
              文件保存地址：<el-input v-model="input" placeholder="文件保存地址"></el-input>
            </div>
            <div style="width: 100%; height: 250px; font-size: 15px">
              邮箱:<el-input
                placeholder="请输入邮箱"
                v-model="emallinput"
                clearable
              ></el-input>
              备注：<el-input
                v-model="beizhuinput"
                placeholder="请输入内容"
                clearable
              ></el-input>
              <div style="font-size:8px display: flex">
                诊断方法选择：<el-select
                  v-model="value"
                  style="width: 80px"
                  size="mini"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-button size="mini">开始诊断</el-button>
                <el-button size="medium" type="primary" plain>保存设定数据 </el-button>
                <el-button size="medium" type="success" plain>采集正常信号</el-button>
                <el-button size="medium" type="info" plain>载入正常信号</el-button>
              </div>
            </div>
            <div style="width: 100%; height: 90px" id="input7">
              数据缓存:<el-button class="Rbutton" icon="el-icon-refresh" circle></el-button>
              <br />数据缓存进度：<el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="80"
                status="success"
              ></el-progress>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
  import * as echarts from "echarts";
  import https from '@/utils/request0';
  
  export default {
    data() {
      return {
        emallinput: "",
        beizhuinput: "",
        fits: ["fill"],
        url: require("@/image/red.png"),
        url1: require("@/image/green.png"),
        options: [
          {
            value: "选项1",
            label: "传统",
          },
          {
            value: "选项2",
            label: "智能",
          },
        ],
        value: "",
        ids:1,
        freshingData:{
          pressureInlet:0,
          rateFlow:0,
          voltage0:0,
          voltage1:0,
          voltage2:0,
          effectivePowerPump:0,
          speedPump:0,
          pressureOutlet:0,
          headPump:0,
          current0:0,
          current1:0,
          current2:0,
          outputPowerMotor:0,
          deviceEfficiency:0
        },
  
        option1:{
          
          title: {
            text: "外特性曲线",
            x: "center",
            textStyle: {
              fontSize: 15,
            },
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["Q-H", "Q-η", "Q-P", "Fit-H", "Fit-η", "Fit-P"],
            x: "center",
            top: "6%",
            itemHeight: 6,
          },
          grid: {
            left: "3%",
            right: "12%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "value",
            name: "Q(m3/h)",
            boundaryGap: false,
            axisTick: {show: false}, 
            data: ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100","110","120"],
          },
          yAxis: [
            {
              type: "value",
              name: "H(m)",
              position: "left",
              min: 0,
              max: 30,
              axisLabel: {
                formatter: "{value}"
              }
            },
            {
              type: "value",
              name: "效率(%)",
              position: "right",
              offset:50,
              min: -6,
              max: 12,
              axisLabel: {
                formatter: "{value}"
              }
            },
            {
              type: "value",
              name: "功率(km)",
              position: "right",
              min: 0,
              max: 12,
              axisLabel: {
                formatter: "{value}"
              }
            },
          ],
          series:[
            { 
              name: "Q-H",
              type: 'line',
              data: [],
              yAxisIndex: 0,
            },
            {
              name: "Q-η",
              type: "line",
              data: [],
              yAxisIndex: 1,
            },
            {
              name: "Q-P",
              type: "line",
              data: [],
              yAxisIndex: 2,
            },
            {
              name: "Fit-H",
              type: "line",
              data: [],
              yAxisIndex: 0,
            },
            {
              name: "Fit-η",
              type: "line",
              data: [],
              yAxisIndex: 1,
            },
            {
              name: "Fit-P",
              type: "line",
              data: [],
              yAxisIndex: 2,
            },
          ],
        
        },
        option2:{
          title: {
            text: "流量变化曲线",
            x: "center",
            textStyle: {
              fontSize: 15,
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, '50%'],
            max:150,
            min:50
          },
          grid: {
            left: "3%",
            right: "12%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              data: [],
              type: "line",
              smooth: true,
            },
          ],
        },
        option3:{
          title: {
            text: "扬程变化曲线",
            x: "center",
            textStyle: {
              fontSize: 15,
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, '50%'],
            max:7,
            min:3
          },
          grid: {
            left: "3%",
            right: "12%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              data: [],
              type: "line",
              smooth: true,
            },
          ],
        },
        option4:{
          title: {
            text: "电机输出功率变化曲线",
            x: "center",
            textStyle: {
              fontSize: 15,
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, '50%'],
            max:1.9,
            min:1.5
          },
          grid: {
            left: "3%",
            right: "12%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              data: [],
              type: "line",
              smooth: true,
            },
          ],
        },
        option5:{
          title: {
            text: "泵组效率变化曲线",
            x: "center",
            textStyle: {
              fontSize: 15,
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, '50%'],
            max:5,
            min:-5
          },
          grid: {
            left: "3%",
            right: "12%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              data: [],
              type: "line",
              smooth: true,
            },
          ],
        },
      };
    },
    methods:{
      changeMenu(item) {
        this.$router.push({ name: item });
      },
      async startPolling() {
        const fetchData = async () => {
            await https.post(`/home/info/${this.ids}`)
            .then(response =>{
              this.freshingData=response.data.data
              this.ids=this.ids+1
              this.option1.series[3].data=[[response.data.data.rateFlow,response.data.data.headPump],[response.data.data.rateFlow,0]]
              this.option1.series[4].data=[[response.data.data.rateFlow,response.data.data.deviceEfficiency],[response.data.data.rateFlow,0]]
              this.option1.series[5].data=[[response.data.data.rateFlow,response.data.data.effectivePowerPump],[response.data.data.rateFlow,0]]
              var line1Dom = document.getElementById("line1");
              var line1Chart = echarts.init(line1Dom);
              line1Chart.setOption(this.option1);
  
              this.option2.series[0].data.push([response.data.data.id,response.data.data.rateFlow])
              var line2Dom = document.getElementById("line2");
              var line2Chart = echarts.init(line2Dom);
              line2Chart.setOption(this.option2);
  
              this.option3.series[0].data.push([response.data.data.id,response.data.data.headPump])
              var line3Dom = document.getElementById("line3");
              var line3Chart = echarts.init(line3Dom);
              line3Chart.setOption(this.option3);
  
              this.option4.series[0].data.push([response.data.data.id,response.data.data.effectivePowerPump])
              var line4Dom = document.getElementById("line4");
              var line4Chart = echarts.init(line4Dom);
              line4Chart.setOption(this.option4);
  
              this.option5.series[0].data.push([response.data.data.id,response.data.data.deviceEfficiency])
              var line5Dom = document.getElementById("line5");
              var line5Chart = echarts.init(line5Dom);
              line5Chart.setOption(this.option5);
              if(this.ids>10){
                this.option2.series[0].data.shift()
                this.option3.series[0].data.shift()
                this.option4.series[0].data.shift()
                this.option5.series[0].data.shift()
              }
            });
            
        };
        this.pollingIntervalId = setInterval(fetchData, 2000);
      },
      stopPolling() {
        clearInterval(this.pollingIntervalId);
      },
    },
    beforeDestroy() {
      this.stopPolling();
    },
    mounted() {
      this.startPolling()
      https.post(`/home/head/`,{
        params: {}, 
      }).then
      (response => {
        const dataAskQH = response.data.data.map(element => [element.flowRate, element.head]);
        this.option1.series[0].data=dataAskQH
        const dataAskQn = response.data.data.map(element => [element.flowRate, element.efficiency]);
        this.option1.series[1].data=dataAskQn
        const dataAskQP = response.data.data.map(element => [element.flowRate, element.power]);
        this.option1.series[2].data=dataAskQP
  
        var line1Dom = document.getElementById("line1");
        var line1Chart = echarts.init(line1Dom);
        line1Chart.setOption(this.option1);
        window.addEventListener("resize", () => {
        line1Chart.resize();
        });
      });
  
  
      var line2Dom = document.getElementById("line2");
      var line2Chart = echarts.init(line2Dom);
      line2Chart.setOption(this.option2);
      window.addEventListener("resize", () => {
        line2Chart.resize();
      });
  
      var line3Dom = document.getElementById("line3");
      var line3Chart = echarts.init(line3Dom);
      line3Chart.setOption(this.option3);
      window.addEventListener("resize", () => {
        line3Chart.resize();
      });
  
      var line4Dom = document.getElementById("line4");
      var line4Chart = echarts.init(line4Dom);
      line4Chart.setOption(this.option4);
      window.addEventListener("resize", () => {
        line4Chart.resize();
      });
  
      var line5Dom = document.getElementById("line5");
      var line5Chart = echarts.init(line5Dom);
      line5Chart.setOption(this.option5);
  
      window.addEventListener("resize", () => {
        line5Chart.resize();
      });
    },
    beforeMount(){
      
    }
  };
  </script>
  
  <style lang="less" scoped>
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  
  .el-icon-arrow-down {
    font-size: 8px;
  }
  
  .Rbutton {
    margin-top: 25px;
  }
  .demo-image {
    margin-left: 5px;
    margin-right: 30px;
  }
  </style>
  