<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="11">
        <el-card>
          <div style="width: 100%; height: 75px" id="Sline1"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Sline2"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Sline3"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Sline4"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Sline5"></div>
        </el-card>
      </el-col>
      <el-col :span="11">
        <el-card>
          <div style="width: 100%; height: 75px" id="Pline1"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Pline2"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Pline3"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Pline4"></div>
        </el-card>
        <el-card>
          <div style="width: 100%; height: 75px" id="Pline5"></div>
        </el-card>
      </el-col>
      <el-col :span="2">
        <el-card>
          <el-button size="small" type="primary">显示图</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import https from '@/utils/request0';


export default {
  data() {
    return {
      ids:1,

      option : {
        title: {
          text: "电流0时域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "时间",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "加速度",
          max:1.5,
          min:-1.5
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option2 : {
        title: {
          text: "电流1时域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "时间",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "加速度",
          max:1.5,
          min:-1.5
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option3 : {
        title: {
          text: "电流2时域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "时间",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "加速度",
          max:1.5,
          min:-1.5
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option4 : {
        title: {
          text: "进口压力时域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "时间",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "加速度",
          max:1.2,
          min:1.0
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option5 : {
        title: {
          text: "电流0频域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "频率",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "幅度",
          max:300,
          min:0
        },

        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option6 : {
        title: {
          text: "电流1频域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "频率",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "幅度",
          max:350,
          min:0
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option7 : {
        title: {
          text: "电流2频域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "频率",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "幅度",
          max:300,
          min:0
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option8 : {
        title: {
          text: "进口压力频域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "频率",
        },
        grid: {
          x: 75,
          y: 25,
          x2: 55,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "幅度",
          max:1500,
          min:0
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option9 : {
        title: {
          text: "出口压力时域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "时间",
        },
        grid: {
          x: 75,
          y: 14,
          x2: 35,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "加速度",
          max:2.9,
          min:2.7
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      option10 : {
        title: {
          text: "出口压力频域图",
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        xAxis: {
          type: "category",
          name: "频率",
        },
        grid: {
          x: 75,
          y: 14,
          x2: 35,
          y2: 5,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          name: "幅度",
          max:1500,
          min:0
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
    };
  },
  methods:{
    async startPolling() {
      const fetchData = async () => {
          this.ids=this.ids+1
          await https.post(`/curve/cptime/${this.ids}`)
          .then(response =>{
            this.option.series[0].data.push([response.data.data.id,response.data.data.current0])
            var Sline1Dom = document.getElementById("Sline1");
            var Sline1Chart = echarts.init(Sline1Dom);
            Sline1Chart.setOption(this.option);

            this.option2.series[0].data.push([response.data.data.id,response.data.data.current1])
            var Sline2Dom = document.getElementById("Sline2");
            var Sline2Chart = echarts.init(Sline2Dom);
            Sline2Chart.setOption(this.option2);

            this.option3.series[0].data.push([response.data.data.id,response.data.data.current2])
            var Sline3Dom = document.getElementById("Sline3");
            var Sline3Chart = echarts.init(Sline3Dom);
            Sline3Chart.setOption(this.option3)

            this.option4.series[0].data.push([response.data.data.id,response.data.data.pressureInlet])
            var Sline4Dom = document.getElementById("Sline4");
            var Sline4Chart = echarts.init(Sline4Dom);
            Sline4Chart.setOption(this.option4);

            this.option9.series[0].data.push([response.data.data.id,response.data.data.pressureOutlet])
            var Sline5Dom = document.getElementById("Sline5");
            var Sline5Chart = echarts.init(Sline5Dom);
            Sline5Chart.setOption(this.option9);

            if(this.ids>10){
              this.option.series[0].data.shift()
              this.option2.series[0].data.shift()
              this.option3.series[0].data.shift()
              this.option4.series[0].data.shift()
              this.option9.series[0].data.shift()
            }
          })
          await https.post(`/curve/cpfreq/${this.ids}`)
          .then(response =>{
            this.option5.series[0].data.push([response.data.data.id,response.data.data.current0])
            var Pline1Dom = document.getElementById("Pline1");
            var Pline1Chart = echarts.init(Pline1Dom);
            Pline1Chart.setOption(this.option5);

            this.option6.series[0].data.push([response.data.data.id,response.data.data.current1])
            var Pline2Dom = document.getElementById("Pline2");
            var Pline2Chart = echarts.init(Pline2Dom);
            Pline2Chart.setOption(this.option6);

            this.option7.series[0].data.push([response.data.data.id,response.data.data.current2])
            var Pline3Dom = document.getElementById("Pline3");
            var Pline3Chart = echarts.init(Pline3Dom);
            Pline3Chart.setOption(this.option7);

            this.option8.series[0].data.push([response.data.data.id,response.data.data.pressureInlet])
            var Pline4Dom = document.getElementById("Pline4");
            var Pline4Chart = echarts.init(Pline4Dom);
            Pline4Chart.setOption(this.option8);

            this.option10.series[0].data.push([response.data.data.id,response.data.data.pressureOutlet])
            var Pline5Dom = document.getElementById("Pline5");
            var Pline5Chart = echarts.init(Pline5Dom);
            Pline5Chart.setOption(this.option10);

            if(this.ids>10){
              this.option5.series[0].data.shift()
              this.option6.series[0].data.shift()
              this.option7.series[0].data.shift()
              this.option8.series[0].data.shift()
              this.option10.series[0].data.shift()
            }
          })
          

      }
      this.pollingIntervalId = setInterval(fetchData, 3000);
    },
    stopPolling() {
      clearInterval(this.pollingIntervalId);
    },
    
  },
  beforeDestroy() {
    this.stopPolling();
  },
  mounted() {
    this.startPolling()
    var Sline1Dom = document.getElementById("Sline1");
    var Sline1Chart = echarts.init(Sline1Dom);
    Sline1Chart.setOption(this.option);

    var Sline2Dom = document.getElementById("Sline2");
    var Sline2Chart = echarts.init(Sline2Dom);
    Sline2Chart.setOption(this.option2);

    var Sline3Dom = document.getElementById("Sline3");
    var Sline3Chart = echarts.init(Sline3Dom);
    Sline3Chart.setOption(this.option3);

    var Sline4Dom = document.getElementById("Sline4");
    var Sline4Chart = echarts.init(Sline4Dom);
    Sline4Chart.setOption(this.option4);

    var Sline5Dom = document.getElementById("Sline5");
    var Sline5Chart = echarts.init(Sline5Dom);
    Sline5Chart.setOption(this.option9);

    var Pline1Dom = document.getElementById("Pline1");
    var Pline1Chart = echarts.init(Pline1Dom);
    Pline1Chart.setOption(this.option5);

    var Pline2Dom = document.getElementById("Pline2");
    var Pline2Chart = echarts.init(Pline2Dom);
    Pline2Chart.setOption(this.option6);

    var Pline3Dom = document.getElementById("Pline3");
    var Pline3Chart = echarts.init(Pline3Dom);
    Pline3Chart.setOption(this.option7);

    var Pline4Dom = document.getElementById("Pline4");
    var Pline4Chart = echarts.init(Pline4Dom);
    Pline4Chart.setOption(this.option8);

    var Pline5Dom = document.getElementById("Pline5");
    var Pline5Chart = echarts.init(Pline5Dom);
    Pline5Chart.setOption(this.option10);

    window.addEventListener("resize", () => {
      Sline1Chart.resize();
    });
    window.addEventListener("resize", () => {
      Sline2Chart.resize();
    });
    window.addEventListener("resize", () => {
      Sline3Chart.resize();
    });
    window.addEventListener("resize", () => {
      Sline4Chart.resize();
    });
    window.addEventListener("resize", () => {
      Sline5Chart.resize();
    });
    window.addEventListener("resize", () => {
      Pline1Chart.resize();
    });
    window.addEventListener("resize", () => {
      Pline2Chart.resize();
    });
    window.addEventListener("resize", () => {
      Pline3Chart.resize();
    });
    window.addEventListener("resize", () => {
      Pline4Chart.resize();
    });
    window.addEventListener("resize", () => {
      Pline5Chart.resize();
    });
  },
};
</script>

<style></style>
