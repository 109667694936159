<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="6">
        <el-card>
          <div style="text-align: center">电机出厂曲线参数</div>
          <template>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="id" label="序号" width="50px"> </el-table-column>
              <el-table-column prop="p2" label="p2额定功率" width="100px">
              </el-table-column>
              <el-table-column prop="cos" label="cos" width="58px"> </el-table-column>
              <el-table-column prop="eff" label="eff" width="58px"> </el-table-column>
            </el-table>
          </template>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-button class="Button" type="primary" size="small" round
          >保存电机参数</el-button
        >
        <el-card>
          <div style="width: 100%; height: 540px" id="line"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import https from '@/utils/request0';



export default {
  data() {
    return {
      tableData:[],

      option :{
        title: {
          text: "电机特性曲线",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["P1-cos", "P1-eff"],
          left: "left",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "value",
          boundaryGap: false,
          axisTick: {show: false}, 
          data: ["0", "0.2", "0.4", "0.6", "0.8", "1.0","1.2","1.4","1.6"],
        },
        yAxis: [
          {
            type: "value",
            name: "cos",
            position: "left",
            axisLabel: {
              formatter: "{value}"
            }
          },
          {
            type: "value",
            name: "eff",
            position: "right",
            axisLabel: {
              formatter: "{value}"
            }
          },
        ],
        series: [
          {
            name: "P1-cos",
            type: "line",
            data: [],
            yAxisIndex: 0,
          },
          {
            name: "P1-eff",
            type: "line",
            data: [],
            yAxisIndex: 1,
          },
        ],
      },
    };
  },
  mounted() {
    https.post(`/motor/value/`,{
      params: {}, 
    }).then
    (response => {
      this.tableData=response.data.data
      
      const dataAskcos = response.data.data.map(element => [element.p2, element.cos]);
      this.option.series[0].data=dataAskcos
      const dataAskeff = response.data.data.map(element => [element.p2, element.eff]);
      this.option.series[1].data=dataAskeff
      console.log(dataAskcos)

      var lineDom = document.getElementById("line");
      var lineChart = echarts.init(lineDom);
      lineChart.setOption(this.option);
      window.addEventListener("resize", () => {
        lineChart.resize();
      });
    })
    
  },
};
</script>

<style lang="less" scrop>
.Button {
  float: right;
}
</style>
