<template>
  <div>
    <el-row type="flex">
      <el-col :span="8">
        <el-card>
          <div class="title" style="width: 100%">输入额定泵参数</div>
          <div style="width: 100%; display: flex">
            <div class="Form1">
              <el-form
                :label-position="labelPosition"
                :model="formLabelAlign"
                size="mini"
              >
                <el-form-item label="额定流量Q0(m3/h)">
                  <el-input v-model="formLabelAlign.liuliang" ></el-input>
                </el-form-item>
                <el-form-item label="额定转速n0(r/min)">
                  <el-input v-model="formLabelAlign.zhuansu"></el-input>
                </el-form-item>
                <el-form-item label="额定扬程H0(m)">
                  <el-input v-model="formLabelAlign.yangchen"></el-input>
                </el-form-item>
                <el-form-item label="额定功率W0(Kw)">
                  <el-input v-model="formLabelAlign.gonglv"></el-input>
                </el-form-item>
                <div>泵类型</div>
                <el-select v-model="value" placeholder="泵类型" size="mini">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form>
            </div>
            <div class="Form2">
              <el-form
                :label-position="labelPosition"
                :model="formLabelAlign"
                size="mini"
              >
                <el-form-item label="额定泵效率η0(%)">
                  <el-input v-model="formLabelAlign.xiaolv"></el-input>
                </el-form-item>
                <el-form-item label="叶轮片片数(r/min)">
                  <el-input v-model="formLabelAlign.pianshu"></el-input>
                </el-form-item>
                <el-form-item label="电机极数">
                  <el-input v-model="formLabelAlign.jishu"></el-input>
                </el-form-item>
                <div>电机接法</div>
                <el-select v-model="value2" placeholder="电机接法" size="mini">
                  <el-option
                    v-for="item in options2"
                    :key="item.value2"
                    :label="item.label2"
                    :value="item.value2"
                  >
                  </el-option>
                </el-select>
              </el-form>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div class="title" style="width: 100%">非驱动端轴承参数</div>
          <div style="display: flex">
            <div style="width: 100%; display: flex">
              <div class="Form1">
                <el-form
                  :label-position="labelPosition"
                  :model="formLabelAlign"
                  size="mini"
                >
                  <div>轴承型号</div>
                  <el-select v-model="value3" placeholder="轴承型号" size="mini">
                    <el-option
                      v-for="item in options3"
                      :key="item.value3"
                      :label="item.label3"
                      :value="item.value3"
                    >
                    </el-option>
                  </el-select>
                  <el-form-item label="轴承节径D(mm)">
                    <el-input v-model="formLabelAlign.jiejin1"></el-input>
                  </el-form-item>
                  <el-form-item label="滚动体直径d(mm)">
                    <el-input v-model="formLabelAlign.zhijin1"></el-input>
                  </el-form-item>
                  <el-form-item label="接触角角度α">
                    <el-input v-model="formLabelAlign.jiaodu1"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="Form2">
                <el-form
                  :label-position="labelPosition"
                  :model="formLabelAlign"
                  size="mini"
                >
                  <div>轴承润滑</div>
                  <el-select v-model="value4" placeholder="轴承润滑" size="mini">
                    <el-option
                      v-for="item in options4"
                      :key="item.value4"
                      :label="item.label4"
                      :value="item.value4"
                    >
                    </el-option>
                  </el-select>
                  <el-form-item label="滚动体数目Z">
                    <el-input v-model="formLabelAlign.shumu1"></el-input>
                  </el-form-item>
                  <el-form-item label="轴承静载荷(KN)">
                    <el-input v-model="formLabelAlign.jing1"></el-input>
                  </el-form-item>
                  <el-form-item label="轴承动载荷(KN)">
                    <el-input v-model="formLabelAlign.dong1"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div class="title" style="width: 100%">驱动端轴承参数</div>
          <div style="display: flex">
            <div style="width: 100%; display: flex">
              <div class="Form1">
                <el-form
                  :label-position="labelPosition"
                  :model="formLabelAlign"
                  size="mini"
                >
                  <div>轴承型号</div>
                  <el-select v-model="value3" placeholder="轴承型号" size="mini">
                    <el-option
                      v-for="item in options3"
                      :key="item.value3"
                      :label="item.label3"
                      :value="item.value3"
                    >
                    </el-option>
                  </el-select>
                  <el-form-item label="轴承节径D(mm)">
                    <el-input v-model="formLabelAlign.jiejin2"></el-input>
                  </el-form-item>
                  <el-form-item label="滚动体直径d(mm)">
                    <el-input v-model="formLabelAlign.zhijin2"></el-input>
                  </el-form-item>
                  <el-form-item label="接触角角度α">
                    <el-input v-model="formLabelAlign.jiaodu2"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="Form2">
                <el-form
                  :label-position="labelPosition"
                  :model="formLabelAlign"
                  size="mini"
                >
                  <div>轴承润滑</div>
                  <el-select v-model="value4" placeholder="轴承润滑" size="mini">
                    <el-option
                      v-for="item in options4"
                      :key="item.value4"
                      :label="item.label4"
                      :value="item.value4"
                    >
                    </el-option>
                  </el-select>
                  <el-form-item label="滚动体数目Z">
                    <el-input v-model="formLabelAlign.shumu2"></el-input>
                  </el-form-item>
                  <el-form-item label="轴承静载荷(KN)">
                    <el-input v-model="formLabelAlign.jing2"></el-input>
                  </el-form-item>
                  <el-form-item label="轴承动载荷(KN)">
                    <el-input v-model="formLabelAlign.dong2"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </el-card>
        <el-button class="Button" type="primary">保存泵及轴承参数</el-button>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="8">
        <el-card>
          <template>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="id" label="序号" width="45px"> </el-table-column>
              <el-table-column prop="flowRate" label="流量"> </el-table-column>
              <el-table-column prop="head" label="扬程"> </el-table-column>
              <el-table-column prop="power" label="功率"> </el-table-column>
              <el-table-column prop="efficiency" label="效率"> </el-table-column>
            </el-table>
          </template>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div style="width: 100%; height: 350px" id="line1"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import https from '@/utils/request0';

export default {
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        liuliang: "",
        zhuansu: "",
        yangchen: "",
        gonglv: "",
        xiaolv: "",
        pianshu: "",
        jishu: "",
        jiejin1: "",
        zhijin1: "",
        jiaodu1: "",
        shumu1: "",
        jing1: "",
        dong1: "",
        jiejin2: "",
        zhijin2: "",
        jiaodu2: "",
        shumu2: "",
        jing2: "",
        dong2: "",
      },
      options: [
        {
          value: "选项1",
          label: "立式泵",
        },
        {
          value: "选项2",
          label: "卧式泵",
        },
      ],
      value: "",

      options2: [
        {
          value2: "选项1",
          label2: "星形",
        },
        {
          value2: "选项2",
          label2: "三角",
        },
      ],
      value2: "",

      tableData:[],

      option1:{
        title: {
          text: "外特性曲线",
          x: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Q-H", "Q-η", "Q-P", "Fit-H", "Fit-η", "Fit-P"],
          x: "center",
          top: "6%",
          itemHeight: 6,
        },
        grid: {
          left: "3%",
          right: "12%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "value",
          name: "Q(m3/h)",
          boundaryGap: false,
          axisTick: {show: false}, 
          data: ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100","110","120"],
        },
        yAxis: [
          {
            type: "value",
            name: "H(m)",
            position: "left",
            min: 0,
            max: 30,
            axisLabel: {
              formatter: "{value}"
            }
          },
          {
            type: "value",
            name: "效率(%)",
            position: "right",
            offset:50,
            min: -6,
            max: 12,
            axisLabel: {
              formatter: "{value}"
            }
          },
          {
            type: "value",
            name: "功率(km)",
            position: "right",
            min: 0,
            max: 12,
            axisLabel: {
              formatter: "{value}"
            }
          },
        ],
        series:[
          { 
            name: "Q-H",
            type: 'line',
            data: [],
            yAxisIndex: 0,
          },
          {
            name: "Q-η",
            type: "line",
            data: [],
            yAxisIndex: 1,
          },
          {
            name: "Q-P",
            type: "line",
            data: [],
            yAxisIndex: 2,
          },
        ],
      
      },
    };
  },
  mounted() {
    https.post(`/home/head/`,{
      params: {}, 
    }).then
    (response => {
      this.tableData=response.data.data

      const dataAAskQH = response.data.data.map(element => [element.flowRate, element.head]);
      this.option1.series[0].data=dataAAskQH
      const dataAAskQn = response.data.data.map(element => [element.flowRate, element.efficiency]);
      this.option1.series[1].data=dataAAskQn
      const dataAAskQP = response.data.data.map(element => [element.flowRate, element.power]);
      this.option1.series[2].data=dataAAskQP

      var line1Dom = document.getElementById("line1");
      var line1Chart = echarts.init(line1Dom);
      line1Chart.setOption(this.option1);
      window.addEventListener("resize", () => {
      line1Chart.resize();
      });
    });
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
}
.Button {
  float: right;
}
</style>
