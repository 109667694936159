<template>
  <el-row :gutter="5">
    <el-col :span="20">
      <el-card>
        <div style="width: 100%; height: 270px; display: flex">
          <div class="box">
            <h6>振动传感器0设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                style="width: 100px"
                size="mini"
                v-model="num"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                style="width: 100px"
                size="mini"
                v-model="num1"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num2"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num3"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>振动传感器1设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num4"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num5"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num6"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num7"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>振动传感器2设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num8"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num9"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num10"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num11"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>振动传感器3设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num12"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num13"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num14"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num15"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>振动传感器4设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num16"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num17"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num18"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值G<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num19"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>电流传感器0设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num20"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num21"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值A<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num22"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值A<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num23"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>电流传感器1设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num24"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num25"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值A<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num26"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值A<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num27"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>电流传感器2设置</h6>
            <div style="width: 100px; height: 270px; font-size: 12px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num28"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num29"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值A<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num30"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最大值A<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num31"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 270px; display: flex">
          <div class="box">
            <h6>流量传感器设置</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值V<el-input-number
                style="width: 100px"
                size="mini"
                v-model="num32"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                style="width: 100px"
                size="mini"
                v-model="num33"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值m3/h<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num34"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值m3/h<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num35"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
          <div class="box" style="font-size: 16px">
            <h6>进口压力传感器设置</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num36"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num37"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值Kpa<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num38"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值Kpa<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num39"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
          <div class="box" style="font-size: 16px">
            <h6>出口压力传感器设置</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num40"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num41"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值Kpa<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num42"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值Kpa<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num43"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>电压传感器0设置</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num44"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num45"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num46"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num47"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>电压传感器1设置</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num48"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num49"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num50"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num51"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>电压传感器2设置</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num52"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num53"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num54"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num55"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>转速传感器设置</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num56"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值V<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num57"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值rpm<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num58"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值rpm<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num59"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
          <div class="box">
            <h6>NONE</h6>
            <div style="width: 100px; height: 270px; font-size: 10px">
              输出信号最小值<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num60"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              输出信号最大值<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num61"
                controls-position="left"
                @change="handleChange"
                :min="0"
                :max="100"
              ></el-input-number>
              采集量程最小值<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num62"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
              采集量程最大值<el-input-number
                size="mini"
                style="width: 100px"
                v-model="num63"
                controls-position="left"
                @change="handleChange"
                :min="-5000"
                :max="5000"
              ></el-input-number>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="4">
      <el-card>
        <el-button>保存传感器参数</el-button>
        <div style="width: 100%; height: 470px">
          <h5>传感器状态</h5>
          振动传感器0<el-switch
            class="switch1"
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>

          <div>
            振动传感器1<el-switch
              class="switch1"
              v-model="value2"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            振动传感器2<el-switch
              v-model="value3"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            振动传感器3<el-switch
              v-model="value4"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            振动传感器4<el-switch
              v-model="value5"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            电流传感器0<el-switch
              v-model="value6"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            电流传感器1<el-switch
              v-model="value7"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            电流传感器2<el-switch
              v-model="value8"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            流量传感器<el-switch
              v-model="value9"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            进口压力传感器<el-switch
              v-model="value10"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            出口压力传感器<el-switch
              v-model="value11"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            电压传感器0<el-switch
              v-model="value12"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            电压传感器1<el-switch
              v-model="value13"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            电压传感器2<el-switch
              v-model="value14"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            转速传感器<el-switch
              v-model="value15"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div>
            NONE<el-switch
              v-model="value16"
              class="switch1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
      num1: 5,
      num2: 0,
      num3: 50,
      num4: 0,
      num5: 5,
      num6: 0,
      num7: 50,
      num8: 0,
      num9: 5,
      num10: 0,
      num11: 50,
      num12: 0,
      num13: 5,
      num14: 0,
      num15: 50,
      num16: 0,
      num17: 5,
      num18: 0,
      num19: 50,
      num20: 0,
      num21: 5,
      num22: 0,
      num23: 50,
      num24: 0,
      num25: 5,
      num26: 0,
      num27: 50,
      num28: 0,
      num29: 5,
      num30: 0,
      num31: 50,
      num32: 0,
      num33: 10,
      num34: 0,
      num35: 160,
      num36: 0,
      num37: 5,
      num38: -100,
      num39: 1600,
      num40: 0,
      num41: 5,
      num42: -100,
      num43: 1600,
      num44: 0,
      num45: 5,
      num46: 0,
      num47: 500,
      num48: 0,
      num49: 5,
      num50: 0,
      num51: 500,
      num52: 0,
      num53: 5,
      num54: 0,
      num55: 500,
      num56: 0,
      num57: 5,
      num58: 0,
      num59: 500,
      num60: 0,
      num61: 0,
      num62: 0,
      num63: 0,
      value: true,
      value2: true,
      value3: true,
      value4: true,
      value5: true,
      value6: true,
      value7: true,
      value8: true,
      value9: true,
      value10: true,
      value11: true,
      value12: true,
      value13: true,
      value14: true,
      value15: true,
      value16: false,
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  margin-right: 28px;
}
h5 {
  text-align: center;
}
</style>
