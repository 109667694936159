import { render, staticRenderFns } from "./Chuanganqi.vue?vue&type=template&id=a7437ed2&scoped=true"
import script from "./Chuanganqi.vue?vue&type=script&lang=js"
export * from "./Chuanganqi.vue?vue&type=script&lang=js"
import style0 from "./Chuanganqi.vue?vue&type=style&index=0&id=a7437ed2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7437ed2",
  null
  
)

export default component.exports