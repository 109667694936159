<template>
    <div style="width:100%;height:100%;overflow: hidden;">
      <div class="routertag">
        <el-tag @click="changeMenu('zhendong')">振动</el-tag>
        <el-tag @click="changeMenu('dianliuyali')">电流及压力脉动</el-tag>
      </div>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        
      };
    },
    methods:{
      changeMenu(item) {
        this.$router.push({ name: item });
      },
      
    },
    beforeDestroy() {
      // this.stopPolling();
    },
    mounted() {
    }
  
  };
  </script>
  
  <style lang="less" scoped>
  .routertag{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  </style>