<template>
  <el-row>
    <el-col :span="10">
      <el-card>
        <div style="width: 100%; height: 500px" id="lineOne"></div>
        <div style="width: 300px">
          非驱动端轴承剩余寿命(h)<el-input v-model="input" :disabled="true"></el-input>
        </div>
      </el-card>
    </el-col>
    <el-col :span="10">
      <el-card>
        <div style="width: 100%; height: 500px" id="lineTwo"></div>
        <div style="width: 300px">
          驱动端轴承剩余寿命(h)<el-input v-model="input" :disabled="true"></el-input>
        </div>
      </el-card>
    </el-col>
    <el-col :span="4">
      <el-card>
        <div>
          <div style="width: 160px; font-size: 13px">
            口环距上次检修(天)<el-input-number
              size="mini"
              style="width: 160px"
              v-model="num"
              controls-position="left"
              @change="handleChange"
              :min="0"
              :max="10000"
            ></el-input-number>
          </div>
          <div style="width: 160px">
            口环剩余寿命<el-input v-model="input" size="mini" :disabled="true"></el-input>
          </div>
        </div>
      </el-card>
      <el-card>
        <div>
          剩余寿命示意<el-progress
            type="circle"
            :percentage="100"
            status="success"
          ></el-progress>
        </div>
        <div class="switch" style="height: 150px">
          开启口环寿命预测<el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import * as echarts from "echarts";

const optionOne = {
  title: {
    text: "非驱动端轴承寿命预测图",
    x: "center",
    textStyle: {
      fontSize: 15,
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["理论上限", "实际曲线", "预测曲线"],
    x: "center",
    top: "8%",
    itemHeight: 5,
  },
  grid: {
    left: "3%",
    right: "12%",
    bottom: "3%",
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: "category",
    name: "Time",
    boundaryGap: false,
    data: ["Mon", "Tue", "Wed"],
  },
  yAxis: [
    {
      type: "value",
      name: "Amplitude",
    },
  ],
  series: [
    {
      name: "理论上限",
      type: "line",
      stack: "Total",
      data: [120, 132, 101, 134, 90, 230, 210, 133],
    },
    {
      name: "实际曲线",
      type: "line",
      stack: "Total",
      data: [220, 182, 191, 234, 290, 330, 310, 564],
    },
    {
      name: "预测曲线",
      type: "line",
      stack: "Total",
      data: [150, 232, 201, 154, 190, 330, 410, 746],
    },
  ],
};
const optionTwo = {
  title: {
    text: "驱动端轴承寿命预测图",
    x: "center",
    textStyle: {
      fontSize: 15,
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["理论上限", "实际曲线", "预测曲线"],
    x: "center",
    top: "8%",
    itemHeight: 5,
  },
  grid: {
    left: "3%",
    right: "12%",
    bottom: "3%",
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: "category",
    name: "Time",
    boundaryGap: false,
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "abc"],
  },
  yAxis: [
    {
      type: "value",
      name: "Amplitude",
    },
  ],
  series: [
    {
      name: "理论上限",
      type: "line",
      stack: "Total",
      data: [120, 132, 101, 134, 90, 230, 210, 133],
    },
    {
      name: "实际曲线",
      type: "line",
      stack: "Total",
      data: [220, 182, 191, 234, 290, 330, 310, 564],
    },
    {
      name: "预测曲线",
      type: "line",
      stack: "Total",
      data: [150, 232, 201, 154, 190, 330, 410, 746],
    },
  ],
};
export default {
  data() {
    return {
      num: 180,
      input: "",
    };
  },
  mounted() {
    var line1Dom = document.getElementById("lineOne");
    var line1Chart = echarts.init(line1Dom);
    line1Chart.setOption(optionOne);

    window.addEventListener("resize", () => {
      line1Chart.resize();
    });

    var line2Dom = document.getElementById("lineTwo");
    var line2Chart = echarts.init(line2Dom);
    line2Chart.setOption(optionTwo);

    window.addEventListener("resize", () => {
      line2Chart.resize();
    });

    handleChange(value);
  },
};
</script>

<style lang="less" scrop>
.switch {
  margin-top: 50px;
}
</style>
