import Mock from 'mockjs'
export default {
    getMenu:config => {
        const { username,password } = JSON.parse(config.body)
        if (username === 'admin' && password === 'skysky11'){
            return{
                code: 20000,
                data:{
                    menu:[
                      {
                        path: "/Home/thehome",
                        name: "home",
                        label: "维修方案决策",
                        icon: "s-home",
                        url: "Home/Home/",
                      },
                      {
                        path: "/zhuangtai",
                        name: "zhuangtai",
                        label: "状态监测日志",
                        icon: "cpu",
                        url: "zhuangtai/zhuangtai",
                      },
                      {
                        path: "/canshu/chuanganqi",
                        name: "canshu",
                        label: "系统参数管理",
                        icon: "setting",
                        url: "canshu/canshu",
                      },
                      {
                        path: "/Database",
                        name: "database",
                        label: "数据库管理",
                        icon: "s-data",
                        url: "Database/Database",
                      },
                    ],
                    token:Mock.Random.guid(),
                    message:'获取成功'
                }
            }
        } else if (username === 'sky' && password ==='123456abc'){
            return{
                code: 20000,
                data:{
                    menu:[
                      {
                        path: "/Home/thehome",
                        name: "home",
                        label: "维修方案决策",
                        icon: "s-home",
                        url: "Home/Home/",
                      },
                      {
                        path: "/zhuangtai",
                        name: "zhuangtai",
                        label: "状态监测日志",
                        icon: "cpu",
                        url: "zhuangtai/zhuangtai",
                      },
                      {
                        path: "/canshu/chuanganqi",
                        name: "canshu",
                        label: "系统参数管理",
                        icon: "setting",
                        url: "canshu/canshu",
                      },
                      {
                        path: "/Database",
                        name: "database",
                        label: "数据库管理",
                        icon: "s-data",
                        url: "Database/Database",
                      },
                    ]
                }
            }
        } else {
            return {
                code:-999,
                data:{
                    message:'密码错误'
                }
            }
        }
    }
}