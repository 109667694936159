<template>
  <div style="overflow: hidden;">

        
        <el-main><!-- 路由出口 -->
          <!-- 路由匹配到的组件将渲染在这里 -->
          <router-view></router-view>
        </el-main>

  </div>
</template>
 
  <script>
  import CommonAside from '../components/CommonAside.vue'
  import CommonHeader from '../components/CommonHeader.vue'
  export default {
      data(){
          return{}
      },
      components:{
        CommonAside,
        CommonHeader,
      }
  
  }
  </script>
  
  <style scoped>
  .el-header{
    padding: 0;
    width: 100%;
  }
  </style>