<template>
    
  <el-form ref="form" :inline="true" class="login-container" :model="form" :rules="rules">
    <h3 class="login_title">系统登录</h3>
    <el-form-item style="margin-left:100px" label="用户名" prop="username">
        <el-input v-model="form.username" placeholder="请输入账号"></el-input>
    </el-form-item>
    <el-form-item style="margin-left:115px" label="密码" prop="password">
        <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
    </el-form-item>
    <el-form-item>
        <el-button @keyup.enter.prevent="keyDown(e)"  @click="submit" style="margin-left:200px;margin-top:10px" type="primary" plain>登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Mock from 'mockjs'
import Cookie from 'js-cookie'
import { getMenu } from '../api/'
export default {
    data(){
        return{
            form:{
                username:'',
                password:''
            },
            rules:{
                username:[
                    {required:true,trigger:'blur',message:'请输入用户名'}
                ],
                password:[
                    {required:true,trigger:'blur',message:'请输入密码'}
                ]
            }
        }
    },
    methods:{
        submit(){
            // const token = Mock.Random.guid()
            // Cookie.set('token',token)
            this.$refs.form.validate((valid) => {
                if (valid){
                    getMenu(this.form).then(({ data}) => {
                        if (data.code === 20000){
                            Cookie.set('token',data.data.token)
                            this.$router.push('/home')
                        } else {
                            this.$message.error('密码错误')
                        }
                    })
                }
            })  
        },
        keyDown (e){
            if (e.keyCode === 13) {
                this.submit()
             }
         },  
    },
    mounted() {
        window.addEventListener('keydown', this.keyDown)
    },
  // 销毁enter事件
    destroyed () {
         window.removeEventListener('keydown', this.keyDown, false)
    }

}
</script>

<style lang="less" scoped>
.login-container{
    width:450px;
    border: 5px solid #eaeaea;
    margin: 200px auto;
    padding: 40px 40px 20px 20px ;
    background-color:aliceblue;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;
}

.login_title{
    margin:0px auto 40px auto;
    text-align:center;
    
}
.login_submit{
    margin: 10px auto 0px auto;
}



</style>