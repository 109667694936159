
<template>
  <div id="app">
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
   <router-view></router-view>
  </div>
</template>

<script>
import https from '@/utils/request0';
import Cookie from 'js-cookie'

export default {
  name: 'App',
  beforeCreate(){
    let href = window.location.href;
    let ticketRegex = /[?&]ticket=([^&]+)/;
    let match = href.match(ticketRegex);
    if(match !== undefined){
      console.log('验证')
      document.cookie = match
    }
    if( Cookie.get('JSESSIONID') === undefined&&match == undefined){
      console.log('未验证')
      window.location = "http://47.102.128.24:8081/cas";
    }
    
  },
  created() {
    //解决vuex数据在页面刷新被重置的问题 

    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state))
    })
  },
}

</script>

<style lang="less">
  html,body{
    margin: 0;
    padding:0;
    height: 100%;
    width: 100%;
  }
  #id{
    height: 100%;
    width: 100%;
  }
</style>
