import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Chuanganqi from '../views/Chuanganqi.vue'
import Bang from '../views/Bang.vue'
import Dianji from '../views/Dianji.vue'
import Zhendong from '../views/Zhendong.vue'
import Dianliuyali from '../views/Dianliuyali.vue'
import Shouming from '../views/Shouming.vue'
import Database from '../views/Database.vue'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import thehome from '../views/thehome.vue'
import jiance from '../views/jiance.vue'
import guzhang from '../views/guzhang.vue'
import zhuangtai from '../views/zhuangtai.vue'
import canshu from '../views/canshu.vue'




Vue.use(VueRouter)

const routes = [
    {
         path: '/', 
         component: Main ,
         redirect:'/home',
         children: [
            { path: 'Home',
              name:'home', 
              component:Home,
              children:[
                {
                  path: 'theHome',
                  name:'thehome', 
                  component:thehome,
                },
                {
                  path: 'jiance',
                  name:'jiance', 
                  component:jiance,
                  children:[
                    { path: 'Zhendong', name:'zhendong',component: Zhendong },
                    { path: 'Dianliuyali',name:'dianliuyali', component: Dianliuyali },
                  ]
                },
                {
                  path: 'guzhang',
                  name:'guzhang', 
                  component:guzhang,
                },
                { path: 'Shouming',
                  name:'shouming',
                  component: Shouming 
                },
              ]
            },
            { path: 'zhuangtai',
              name:'zhuangtai', 
              component: zhuangtai 
            },
            { path: 'canshu',
              name:'canshu', 
              component: canshu,
              children:[
                { path: 'chuanganqi',
                  name:'chuanganqi',
                  component: Chuanganqi,
                },
                { path: 'Bang',
                  name:'bang', 
                  component: Bang },
                { path: 'Dianji', 
                  name:'dianji',
                  component: Dianji },
              ]
            },
            
            { path: 'Database', name:'database',component: Database },
          
         ]
    },

    {
          path:'/Login',
          name:'login',
          component:Login
    }
    

]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
})



export default router


