import axios from 'axios'
// import Cookie from 'js-cookie'

const https = axios.create({
  //通用请求的地址前缀

  baseURL: 'http://47.102.128.24:8088',
  // headers:{
  //   'Content-type':'application/json;charset=UTF-8',
  //   'Cookie':'JSESSIONID=112441BBE81041E51DBEED8189360CB6'
  // }
})



//   config.headers['Content-type'] = 'application/json;charset=UTF-8'
//   config.headers['Cookie'] = 'JSESSIONID=112441BBE81041E51DBEED8189360CB6'

https.interceptors.request.use(
  config => {
    // 在发送请求之前添加请求头
    console.log(config)
    
    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);



export default https