<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="rgb(16, 108, 178)"
    text-color="rgb(255, 255, 255)"
    active-text-color="#409eff"
  >
    <div>
      <h3>{{ isCollapse ? "运维" : "重要厂用水泵智能诊断运维系统" }}</h3>
    </div>
    <el-menu-item
      @click="clickMenu(item)"
      v-for="item in noChildren"
      :key="item.name"
      :index="item.name"
    >
      <i :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <!-- <el-submenu v-for="item in hasChildren" :key="item.label" index="item.label">
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group
        @click="clickMenu(subItem)"
        v-for="subItem in item.children"
        :key="subItem.path"
      >
        <el-menu-item :index="subItem.path">{{ subItem.label }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu> -->
  </el-menu>
</template>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 160px;
  height: 400px;
}
.el-menu {
  border-right: none;
  min-height: 140vh;
  h3 {
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: normal;
  }
  .el-menu-item:hover {
    color: #409eff !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          path: "/Home/thehome",
          name: "home",
          label: "维修方案决策",
          icon: "s-home",
          url: "Home/Home/",
        },
        {
          path: "/zhuangtai",
          name: "zhuangtai",
          label: "状态监测日志",
          icon: "cpu",
          url: "zhuangtai/zhuangtai",
        },
        {
          path: "/canshu/chuanganqi",
          name: "canshu",
          label: "系统参数管理",
          icon: "setting",
          url: "canshu/canshu",
        },
        {
          path: "/Database",
          name: "database",
          label: "数据库管理",
          icon: "s-data",
          url: "Database/Database",
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
    clickMenu(item) {
      console.log(item);
      if (
        this.$route.path !== item.path &&
        !(this.$route.path === "/home" && item.path === "/")
      ) {
        this.$router.push(item.path);
      }
      this.$store.commit("selectMenu", item);
    },
  },

  computed: {
    noChildren() {
      console.log(this.menuData.filter((item) => !item.children))
      return this.menuData.filter((item) => !item.children);
    },
    hasChildren() {
      return this.menuData.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>
